import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class Communities extends Base {
  timeout = null;

  /**
   * Component Configuration
   */
  static config = {
    name: 'Communities',
    refs: ['links[]', 'pictures[]'],
  };

  /**
   * Mouse over on items
   * @param  {object} e current Element
   * @returns {void}
   */
  onLinksMouseenter(e) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const { slug } = e.currentTarget.dataset;

    if (this.picture) {
      this.picture.classList.add('opacity-0', 'blur-lg', 'scale-110');
    }

    this.picture = document.querySelector(`[data-picture="${slug}"]`);
    this.picture.classList.remove('opacity-0', 'blur-lg', 'scale-110');
  }
}
