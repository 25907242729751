import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class ToggleCartSummary extends Base {
  timeout = null;

  /**
   * Component Configuration
   */
  static config = {
    name: 'ToggleCartSummary',
  };

  /**
   *
   */
  mounted() {
    this.blocks = {
      first: document.querySelector('#cart-summary-product-list'),
      second: document.querySelector('.block-cart-summary'),
      third: document.querySelector('.block-promo'),
    };
  }

  /**
   *
   */
  onClick() {
    this.blocks.first.classList.toggle('hidden');
    this.blocks.second.classList.toggle('hidden');
    this.blocks.third.classList.toggle('hidden');
  }
}
