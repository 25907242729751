import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class Header extends Base {
  /**
   * Config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'Header',
    refs: ['burger'],
  };

  /**
   * Is the header sticky?
   * @type {boolean}
   */
  isSticky = false;

  /**
   * Mounted hook.
   */
  mounted() {
    this.isSubNav = this.$parent.$children.SubNav.length > 0;
  }

  /**
   * Scrolled hook.
   * @param {import('@studiometa/js-toolkit').ScrollServiceProps} props
   */
  scrolled({ y }) {
    if (y > 30 && !this.isSticky) {
      if (!this.isSubNav) {
        this.$el.classList.add('shadow-card');
      }
      this.$el.classList.add('is-sticky');
      this.isSticky = true;
    }

    if (this.isSticky && y < 200) {
      if (!this.isSubNav) {
        this.$el.classList.remove('shadow-card');
      }
      this.$el.classList.remove('is-sticky');
      this.isSticky = false;
    }
  }

  /**
   * Toggle menu on burger click.
   */
  onBurgerClick() {
    document.body.classList.toggle('open-menu');
  }
}
