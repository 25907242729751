import { Base } from '@studiometa/js-toolkit';
import { isDev } from '../config.js';

/**
 * Main PageCart class.
 */
export default class CustomerForm extends Base {
  static config = {
    name: 'CustomerForm',
    log: isDev(),
    refs: [
      'inputIsBusiness[]',
      'inputIsPartnership[]',
      'holderIsPartnership',
      'holderPartnershipName',
      'holderFiscalNumber',
      'holderIntracommunityVat',
      'holderCompanyName',
      'selectPartnershipName',
    ],
  };

  /**
   * onInputIsBusinessChange
   */
  onInputIsBusinessChange() {
    this.$refs.inputIsBusiness.forEach((item) => {
      if (!item.checked) {
        return;
      }
      this.$refs.holderIsPartnership.classList.toggle('hidden');
      this.$refs.holderFiscalNumber.classList.toggle('hidden');
      this.$refs.holderCompanyName.classList.toggle('hidden');
      this.$refs.inputIsPartnership[0].checked = true;
      this.$refs.holderPartnershipName.classList.add('hidden');
      this.$refs.holderIntracommunityVat.classList.toggle('hidden');
    });
  }

  /**
   * onInputIsPartnershipChange
   */
  onInputIsPartnershipChange() {
    this.$refs.inputIsPartnership.forEach((item) => {
      if (!item.checked) {
        return false;
      }
      return this.$refs.holderPartnershipName.classList.toggle('hidden');
    });
  }

  /**
   * mounted
   */
  mounted() {
    // If profil is business and already selected
    if (document.querySelector('[name="is_business"]:checked').value === '1') {
      return;
    }
    this.$refs.holderIsPartnership.classList.add('hidden');
    this.$refs.holderPartnershipName.classList.add('hidden');
    this.$refs.holderCompanyName.classList.add('hidden');
    this.$refs.holderFiscalNumber.classList.add('hidden');
    this.$refs.holderIntracommunityVat.classList.add('hidden');
  }
}
