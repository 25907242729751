import { Base } from '@studiometa/js-toolkit';

/**
 * TextField component.
 */
export default class TextField extends Base {
  static config = {
    name: 'TextField',
    log: true,
  };

  isFocus = false;

  /**
   *
   */
  mounted() {
    this.$log('mounted');
    this.toggleLabel();
  }

  /**
   *
   */
  toggleLabel() {
    if (!this.isFocus && this.$el.value.length === 0) {
      this.$el.classList.remove('is-filled');
      return;
    }

    this.$el.classList.add('is-filled');
  }

  /**
   *
   */
  onFocus() {
    this.isFocus = true;
    this.toggleLabel();
  }

  /**
   *
   */
  onBlur() {
    this.isFocus = false;
    this.toggleLabel();
  }
}
