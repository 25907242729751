import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class SubNav extends Base {
  /**
   * Config
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'SubNav',
    refs: ['links[]', 'btn', 'dropdown'],
  };

  /**
   * Is the nav sticky?
   * @type {boolean}
   */
  isSticky = false;

  /**
   * Is the dropdown open?
   * @type {boolean}
   */
  dropdownOpen = false;

  /**
   * Mounted hook.
   */
  mounted() {
    this.heightHeader = document.querySelector('[data-component="Header"]').offsetHeight;
    this.stickyPosition = this.$el.offsetTop - this.heightHeader;

    setTimeout(() => {
      if (window.location.hash) {
        window.scrollTo(0, 0);
        this.currentLink = document.querySelector(`[href="${window.location.hash}"]`);
        this.goToAnchor(window.location.hash);
      }
    }, 1);

    this.observe();
  }

  /**
   * Observe sections.
   */
  observe() {
    this.sections = document.querySelectorAll('.sections');

    const observer = new IntersectionObserver(this.checkIntersection.bind(this), {
      threshold: 0.8,
    });

    this.sections.forEach((target) => {
      observer.observe(target);
    });
  }

  /**
   * Check message intersection with mobile
   * @param  {IntersectionObserverEntry[]} entries
   * @returns {void}
   */
  checkIntersection(entries) {
    if (entries[0].isIntersecting) {
      const id = entries[0].target.id;
      this.removeCurrentLinkState();
      this.currentLink = document.querySelector(`[href="#${id}"]`);
      this.changeLinkState(id);
    }
  }

  /**
   * Toggle dropdown on click.
   */
  onBtnClick() {
    if (this.dropdownOpen) {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  }

  /**
   * Hide the dropdown.
   */
  hideDropdown() {
    this.$refs.dropdown.classList.add('hidden');
    this.dropdownOpen = false;
  }

  /**
   * Show the dropdown.
   */
  showDropdown() {
    this.$refs.dropdown.classList.remove('hidden');
    this.dropdownOpen = true;
  }

  /**
   * Scrolled hook.
   * @param {import('@studiometa/js-toolkit').ScrollServiceProps} props
   */
  scrolled({ y }) {
    if (y < 100 && this.currentLink) {
      this.currentLink.classList.remove('bg-blue', 'text-white');
      this.currentLink = null;
    }

    if (y >= this.stickyPosition && !this.isSticky) {
      this.$el.classList.add('shadow-card');
      this.isSticky = true;
    }

    if (y <= this.stickyPosition && this.isSticky) {
      this.$el.classList.remove('shadow-card');
      this.isSticky = false;
    }
  }

  /**
   * Click on tag.
   * @param  {Event} event The event instance.
   * @param  {number} index The index of element.
   * @returns {void}
   */
  onLinksClick(event, index) {
    event.preventDefault();
    this.removeCurrentLinkState();
    this.currentLink = this.$refs.links[index];

    const { slug } = this.currentLink.dataset;
    this.goToAnchor(slug);

    this.hideDropdown();
  }

  /**
   * Update states.
   */
  removeCurrentLinkState() {
    if (this.currentLink) {
      this.currentLink.classList.remove('bg-blue', 'text-white');
      this.currentLink.classList.add('bg-white');
    }
  }

  /**
   * Go to the given anchor.
   * @param {string} slug
   */
  goToAnchor(slug) {
    this.changeLinkState(slug);
    const scrollPosition = document.querySelector(slug).offsetTop - 134;
    window.scrollTo(0, scrollPosition);
  }

  /**
   * Change the given slug state.
   * @param {string} slug
   */
  changeLinkState(slug) {
    window.location.hash = slug;

    if (this.currentLink) {
      this.currentLink.classList.add('bg-blue', 'text-white');
      this.currentLink.classList.remove('bg-white');
    }
  }
}
