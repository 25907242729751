import {
  ScrollAnimationParent as ScrollAnimationParentCore,
  ScrollAnimationChild,
} from '@studiometa/ui';
import { withBreakpointObserver } from '@studiometa/js-toolkit';

/**
 * ScrollAnimation
 */
export default class ScrollAnimationParent extends withBreakpointObserver(
  ScrollAnimationParentCore,
) {
  static config = {
    name: 'ScrollAnimationParent',
    components: {
      ScrollAnimationChild,
    },
    options: {
      inactiveBreakpoints: {
        type: String,
        default: 'xxs xs s',
      },
    },
  };
}
