/**
 * Test if we are on a dev environment.
 * @returns {boolean}
 */
export const isDev = () =>
  !['bigboutique.bpifrance.fr', 'www.bigboutique.bpifrance.fr'].includes(window.location.hostname);

export default {
  isDev,
};
