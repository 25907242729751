import { Base } from '@studiometa/js-toolkit';

/**
 * Dropdown component.
 */
export default class Dropdown extends Base {
  /**
   * Component Configuration
   */
  static config = {
    name: 'Dropdown',
  };

  /**
   * Replace dropdown event from jms_delphinus/theme.js
   * @returns {void}
   */
  onClick() {
    this.$el.classList.add('open', 'show');
    this.$el.nextElementSibling.classList.add('open', 'show');
  }
}
