import { nextFrame } from '@studiometa/js-toolkit/utils';
import {
  Slider as SliderCore,
  SliderDots,
  SliderDrag,
  SliderItem,
  SliderBtn,
} from '@studiometa/ui';

/**
 * Slider.
 */
export default class Slider extends SliderCore {
  /**
   * Config
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    components: {
      SliderDots,
      SliderDrag,
      SliderItem,
      SliderBtn,
    },
    refs: [...SliderCore.config.refs, 'panel', 'panelWrapper', 'panelContent[]'],
    options: {
      ...SliderCore.config.options,
    },
  };

  /**
   * Get the last index.
   * @description Use this.states.length instead of this.$children.SliderItem.length to have correct calculation when using the contain option.
   * @returns {number}
   */
  get indexMax() {
    return this.states.length - 1;
  }

  /**
   * Get the states for each SliderItem.
   * @returns {Array}
   */
  getStates() {
    const { wrapper } = this.$refs;
    const originRect = wrapper.getBoundingClientRect();

    this.origins = {
      left: originRect.left,
      center: originRect.x + originRect.width / 2,
      right: originRect.x + originRect.width,
    };

    const states = this.$children.SliderItem.map((item) => ({
      x: {
        left: (item.rect.x - this.origins.left) * -1,
        center: (item.rect.x + item.rect.width / 2 - this.origins.center) * -1,
        right: (item.rect.x + item.rect.width - this.origins.right) * -1,
      },
    }));

    if (this.$options.contain) {
      const { mode } = this.$options;
      // Find state where last child has passed the wrapper bound completely
      if (mode === 'left') {
        const lastChild = this.$children.SliderItem.at(-1);

        const maxState = states.find((state) => {
          const lastChildPosition =
            lastChild.rect.x - this.origins.left + lastChild.rect.width + state.x.left;
          const diffWithWrapperBound = originRect.width - lastChildPosition;
          if (diffWithWrapperBound > 0) {
            state.x.left = Math.min(state.x.left + diffWithWrapperBound, 0);

            return true;
          }
          return false;
        });

        if (maxState) {
          states.map((state) => {
            state.x.left = Math.max(state.x.left, maxState.x.left);
            return state;
          });

          // @note filter the states to remove duplicate state, in order to remove the number of slides to respect the contain option
          let iterable = 0;
          const s = states.filter((state) => {
            if (state.x.left === maxState.x.left) {
              if (iterable === 0) {
                iterable += 1;
                return true;
              }
              return false;
            }
            return true;
          });

          return s;
        }
      }

      if (mode === 'right') {
        const maxStateIndex = states.findIndex((state) => state.x.right <= 0);
        const maxState = maxStateIndex < 0 ? states.at(-1) : states[maxStateIndex - 1];

        return states.map((state) => {
          state.x.right = maxStateIndex < 0 ? maxState.x.right : Math.min(state.x.right, 0);
          return state;
        });
      }
    }

    return states;
  }

  /**
   * Go to the given index.
   * @param {number} index
   * @param {{ withInstanceMove?: boolean }} [options]
   */
  goTo(index, { withInstantMove = true } = {}) {
    if (index < 0 || index > this.indexMax) {
      throw new Error('Index out of bound.');
    }

    if (this.$refs.panel) {
      const { position } = this.$refs.panelContent[index].dataset;
      if (position === 'right') {
        this.$refs.panel.classList.add('m:translate-x-full');
      } else {
        this.$refs.panel.classList.remove('m:translate-x-full');
      }
      this.$refs.panelWrapper.style.transform = `translateY(${index * -100}%)`;
    }

    const currentState = this.getStateValueByMode(this.currentState.x);

    // @note Test if the state exist since they might have been filtered
    if (this.states[index]) {
      const state = this.getStateValueByMode(this.states[index].x);
      const itemsToMove = this.getVisibleItems(state);
      const invisibleItemsToMoveInstantly = this.getInvisibleItems(state);
      itemsToMove.forEach((item) => {
        // Better perfs when going fast through the slides
        if (currentState !== state && withInstantMove) {
          item.moveInstantly(currentState);
        }
        nextFrame(() => item.move(state));
      });
      invisibleItemsToMoveInstantly.forEach((item) => {
        item.moveInstantly(state);
      });

      this.currentIndex = index;
      this.$emit('goto', index);
    }
  }
}
